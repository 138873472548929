import { css, styled } from "goober";

export const PageWrapper = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const AramChampions = () => css`
  & {
    display: flex;
    justify-content: space-between;
    gap: var(--sp-1);
    padding: var(--sp-6);
    padding-block-start: var(--sp-3_5);
  }

  .bar {
    --bg: var(--shade7);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 3.5rem;

    .fill,
    .padd {
      background: var(--shade6-50);
    }
    .fill,
    .wins {
      border-top-left-radius: var(--br-sm);
      border-top-right-radius: var(--br-sm);
    }
    .fill {
      flex: var(--bar-fill);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .wins {
      flex: var(--wr-fill);
      background: var(--shade5);
    }
    .padd {
      height: var(--sp-2);
    }
  }
`;

export const AramChampionTooltip = () => css`
  & {
    display: grid;
    gap: var(--sp-0_5);
  }
  .stat {
    display: flex;
    gap: var(--sp-8);
    align-items: baseline;
    justify-content: space-between;
    padding: var(--sp-1) var(--sp-2);
    border-radius: var(--br-sm);
    background: var(--shade3-25);
  }
`;

export const ArenaGodChampionsStyle = () => css`
  & {
    display: grid;
    gap: var(--sp-3);
  }
  .progress {
    --progress: 0%;
    position: relative;
    background: var(--shade3-25);
    height: var(--sp-1);
    border-radius: var(--br-sm);
    width: 100%;

    &::after {
      content: "";
      display: block;
      width: calc(var(--progress) * 1%);
      height: 100%;
      background: linear-gradient(
        to right,
        color-mix(in hsl, var(--blue), transparent 50%),
        var(--blue)
      );
      border-radius: var(--br-sm);
    }
  }

  .champions {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: var(--sp-1);
  }
  .champion {
    position: relative;

    svg {
      position: absolute;
      bottom: 0%;
      right: 0%;
      translate: 15% 15%;
      color: var(--green);
      width: 45%;
      height: auto;
      background: var(--shade8);
      box-shadow: 0 0 0 2px var(--shade8);
      border-radius: 50%;
    }
  }
  .champion-img {
    width: 100%;
    height: auto;
  }
`;
