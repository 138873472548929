import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { winRatecolorRange } from "@/app/util.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getDerivedId } from "@/game-lol/utils/util.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const RecentlyPlayedWith = ({ name, region }) => {
  const { t } = useTranslation();
  const {
    lol: { playerStyle },
  } = useSnapshot(readState);

  const playerStyleData = playerStyle?.[getDerivedId(region, name)];

  if (!playerStyleData) {
    return <Card style={{ height: 365 }} />;
  }

  if (
    playerStyleData instanceof Error ||
    !playerStyleData.recentlyPlayedWith.length
  ) {
    return null;
  }

  const recentlyPlayedWith = [...playerStyleData.recentlyPlayedWith].sort(
    (a, b) => b.games - a.games,
  );

  return (
    <ListItemsTruncated
      title={t("common:recentlyPlayedWith", "Recently Played With")}
      list={recentlyPlayedWith}
      onShowMore={() => {
        sendInteractionEvent("profile-recently-played-with-show-more");
      }}
      itemLeftContent={(item) => {
        return (
          <>
            <img
              width="40"
              height="40"
              className="round"
              src={Static.getProfileIcon(item.profileIconId)}
              loading="lazy"
              alt={item.gameName}
            />
            <div>
              <div className="flex gap-sp-1 type-callout--bold">
                <span>{item.gameName}</span>
                <span className="shade1">#{item.tagLine}</span>
              </div>
              <div className="type-caption--semi shade1">
                {t("lol:countGame_plural", "{{count}} Games", {
                  count: item.games,
                })}
              </div>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const { wins, games } = item;
        const winRate = wins / games;
        return (
          <>
            <div
              className="type-caption--bold"
              style={{ color: winRatecolorRange(winRate * 100) }}
            >
              {t("common:winRatePercent", "{{winRate, percent}} WR", {
                winRate,
                formatParams: {
                  maximumFractionDigits: 0,
                },
              })}
            </div>
            <div className="type-caption--semi shade1">
              {t("lol:winsAndLosses", "{{wins}}W {{losses}}L", {
                wins: wins,
                losses: games - wins,
              })}
            </div>
          </>
        );
      }}
      itemLinkFormat={(player) =>
        `/lol/profile/${region}/${player.gameName}-${player.tagLine}`
      }
    />
  );
};

export default memo(RecentlyPlayedWith);
