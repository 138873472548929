import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { kdaColorStyle, winRatecolorRange } from "@/app/util.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import {
  ChampionIcon,
  RoleIconWrapper,
} from "@/game-lol/components/ProfileChampionStats.style.jsx";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { computeChampionStats } from "@/game-lol/utils/compute-champion-stats.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import useSummonerRanks from "@/game-lol/utils/use-summoner-ranks.mjs";
import {
  getARAMStats,
  getDerivedId,
  getDerivedQueue,
  getStaticData,
  isARAMQueue,
} from "@/game-lol/utils/util.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function ProfileChampionsStats({ name, region, queue }) {
  const { t } = useTranslation();
  const {
    lol: { playerChampionStats },
  } = useSnapshot(readState);
  const champions = getStaticData("champions");
  const derivedId = name && getDerivedId(region, name);

  const championsStats =
    playerChampionStats?.[getDerivedQueue(derivedId, queue)];

  const playerRanks = useSummonerRanks(derivedId);
  const aramData =
    playerChampionStats?.[getDerivedQueue(derivedId, QUEUE_SYMBOLS.aram)];
  const aramStats = getARAMStats(playerRanks, aramData);

  if (!championsStats || championsStats instanceof Error) {
    return <Card loading style={{ height: 365 }} />;
  }

  const computedStats = computeChampionStats(championsStats);

  if (!champions || !computedStats.length || aramStats.isARAMPlayer)
    return null;

  return (
    <ListItemsTruncated
      title={t("lol:championPerformance", "Champion Performance")}
      list={computeChampionStats(championsStats)}
      onShowMore={() => {
        sendInteractionEvent("profile-champions-show-more");
      }}
      itemLeftContent={(item) => {
        const { basicStats, role } = item;
        const { kills = 0, deaths = 0, assists = 0 } = basicStats;
        const kda = (kills + assists) / (deaths || 1);
        const champKey = champions?.keys?.[item.championId];
        const champName = champions?.[champKey]?.name;
        const isARAM = isARAMQueue(role);
        const RoleIcon = getRoleIcon(role);

        return (
          <>
            <ChampionIcon>
              <ChampionImg size={36} championId={item.championId} />
              {!isARAM && (
                <RoleIconWrapper className="role-icon">
                  <RoleIcon />
                </RoleIconWrapper>
              )}
            </ChampionIcon>
            <div>
              <p className="type-callout--bold">{champName}</p>
              <p
                className="type-caption--semi"
                style={{ color: kdaColorStyle(kda) }}
              >
                {t("lol:matchHistory.kda", "{{kda}} KDA", {
                  kda: kda.toLocaleString(getLocale(), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                })}
              </p>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const { basicStats, gameCount } = item;
        const {
          wins = 0,
          // lp
        } = basicStats;
        const winrate = wins / (gameCount || 1);

        return (
          <div>
            <p
              className="type-caption--bold"
              style={{
                color: winRatecolorRange(winrate * 100),
              }}
            >
              {t("common:winRatePercent", "{{winRate, percent}} WR", {
                winRate: winrate,
                formatParams: {
                  maximumFractionDigits: 1,
                },
              })}
            </p>
            <p className="type-caption--semi shade1">
              {t("common:stats.winsDashLosses", "{{wins}}W - {{losses}}L", {
                wins: wins ?? 0,
                losses: (gameCount ?? 0) - (wins ?? 0),
              })}
            </p>
          </div>
        );
      }}
      itemLinkFormat={(champ) => {
        const champKey = champions?.keys?.[champ.championId];
        if (!champKey) return null;
        return `/lol/profile/${region}/${name}/champions/${champ.role}/${champ.championId}`;
      }}
    />
  );
}

export default memo(ProfileChampionsStats);
